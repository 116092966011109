@keyframes jump {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(0);
  }
}

.jumping-image {
  animation: jump 1s infinite;
  width: 10%;
  height: auto;
}

.jumping-image-phone {
  animation: jump 1s infinite;
  width: 20%;
  height: auto;
}
