/* NotificationAnimation.css */
@keyframes jitter {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  25% {
    transform: translate(-50%, -50%) rotate(-5deg);
  }
  50% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  75% {
    transform: translate(-50%, -50%) rotate(5deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translate(-50%, -50%) translateY(0);
  }
  50% {
    transform: translate(-50%, -50%) translateY(-10px);
  }
}
@keyframes swivel {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  25% {
    transform: translate(-50%, -50%) rotate(-1deg);
  }
  50% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  75% {
    transform: translate(-50%, -50%) rotate(1deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
}
